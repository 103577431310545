.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.App h1 {
  font-size: 32px;
  font-weight: 300;
}

.App span a::after {
  position: absolute;
  bottom: 15px;
  right: 25px;
  color: #343434;
  font-weight: 100;
  content: attr(data-name) "@"attr(data-domain) "."attr(data-tld)
}
